<template>
  <div class="ordertrue" v-show="isShowPage">
    <div class="ordertrue-bg"></div>
    <!-- 主要内容 -->
    <div class="ordertrue-main">
      <div class="ordertrue-main-address">
        <!-- 没有选择地址 -->
        <div class="flex flex-center align-center ordertrue-main-address-noarea" v-if="addressList.length == 0 || !addressInfo" @click="gotoAddressFunc">
          <img src="../../../assets/img/order/address.png" alt="">添加收货地址
        </div>
        <!-- 有地址 有默认地址 -->
        <div class="flex flex-between align-center ordertrue-main-address-info" v-if="addressInfo && JSON.stringify(addressInfo) != '{}'" @click="gotoAddressFunc">
          <div class="ordertrue-main-address-info-l">
            <p class="ordertrue-main-address-info-l-p1">
              {{addressInfo.addressCity}}{{addressInfo.addressArea}}
            </p>
            <p class="ordertrue-main-address-info-l-p2">
              {{addressInfo.addressCustomerName}}<span>{{addressInfo.addressMobilePhone}}</span>
            </p>
          </div>
          <img class="ordertrue-main-address-info-r" src="../../../assets/img/order/right.png" alt="">
        </div>
        <!-- 有地址 没有默认地址 -->
        <div class="flex flex-center align-center ordertrue-main-address-noarea" v-else @click="gotoAddressFunc">
          <img src="../../../assets/img/order/address.png" alt="">选择收货地址
        </div>
      </div>
      <!-- 商品信息 -->
      <div class="ordertrue-main-goods">
        <div class="flex flex-left align-center ordertrue-main-goods-name">
          <div :style="{background: 'url('+ goodsDetail.brandIcon+')no-repeat center center',backgroundSize: 'cover'}"></div>
          {{goodsDetail.brandName}}
        </div>
        <div class="flex flex-left align-center ordertrue-main-goods-info">
          <div class="ordertrue-main-goods-info-img" :style="{background: 'url('+ goodsUrl +')no-repeat center center',backgroundSize: 'cover'}"></div>
          <div class="ordertrue-main-goods-info-r">
            <div class="ordertrue-main-goods-info-r-name">
              {{goodsDetail.name}}
            </div>
            <div class="flex flex-left ordertrue-main-goods-info-r-guige" v-if="isSpec">
              <div class="flex flex-center align-center ordertrue-main-goods-info-r-guige-s" @click="openGuigeFunc">
                <span>{{spec1Name}}*{{spec2Name}}</span><img src="../../../assets/img/order/down.png" alt="">
              </div>
            </div>
            <div class="flex flex-between align-center ordertrue-main-goods-info-r-price">
              <div class="ordertrue-main-goods-info-r-price-l">¥{{parseFloat(price/100).toFixed(2)}}<span>¥{{parseFloat(originPrice/100).toFixed(2)}}</span></div>
              <span class="ordertrue-main-goods-info-r-price-num">x{{buyNum}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 运费 优惠券 积分 -->
      <div class="ordertrue-main-yh">
        <div class="flex flex-between align-center ordertrue-main-yh-list">
          <span class="ordertrue-main-yh-list-l">运费</span>
          <div class="flex flex-right align-center ordertrue-main-yh-list-r" v-if="freightNum <= 0">
            <span class="ordertrue-main-yh-list-r-span1">包邮</span>¥0.00
          </div>
          <div class="flex flex-right align-center ordertrue-main-yh-list-r" v-if="freightNum > 0">
            ¥{{parseFloat(freightNum/100).toFixed(2)}}
          </div>
        </div>
        <div class="flex flex-between align-center ordertrue-main-yh-list" @click="openCoupon">
          <span class="ordertrue-main-yh-list-l">优惠券</span>
          <div class="flex flex-right align-center ordertrue-main-yh-list-r ordertrue-main-yh-list-r1">
            <span class="" v-if="coupons.length == 0">暂无可用红包</span>
            <span class="ordertrue-main-yh-list-r-span2" v-else>{{couponPrice == 0 ?'请选择优惠券':parseFloat(couponPrice/100).toFixed(2)}}</span>
            <img src="../../../assets/img/order/right.png" alt="">
          </div>
        </div>
        <div class="flex flex-between align-center ordertrue-main-yh-list" v-if="useBount >= 0">
          <div class="ordertrue-main-yh-list-l">
            龙珠<span>可使用{{useBount}}龙珠，抵用￥{{parseFloat(useBountAmount/100).toFixed(2)}}</span>
          </div>
          <div class="flex flex-right align-center ordertrue-main-yh-list-r ordertrue-main-yh-list-r1">
           <van-checkbox v-model="checkedIntegral" checked-color="#E62129" :disabled="useBount == 0"></van-checkbox>
          </div>
        </div>
      </div>
      <!-- 支付方式 -->
      <div class="ordertrue-main-pay">
        <div class="flex flex-between align-center ordertrue-main-pay-list" @click="sPayType = true;">
          <span class="ordertrue-main-pay-list-l">支付方式</span>
          <div class="flex flex-right align-center ordertrue-main-pay-list-r" :class="checkedPayType == 'ALIPAY' ?'ordertrue-main-pay-list-r1':''">
            <img class="ordertrue-main-pay-list-r-img1" :src="checkedPayType == 'WXPAY' ? require('@/assets/img/order/wx.png'):require('@/assets/img/order/alipay.png')" alt="">
            {{checkedPayType == 'WXPAY' ? '微信支付' : '支付宝支付'}}
            <img class="ordertrue-main-pay-list-r-img2" src="../../../assets/img/order/right.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <!-- 种草达人购买协议 -->
    <div class="ordertrue-xy" v-if="goodsDetail.channel == 3">
      <div class="flex flex-left align-center ordertrue-xy-main">
        <van-checkbox v-model="checkedhttp" checked-color="#E62129"></van-checkbox>
        &nbsp;&nbsp;&nbsp;已阅读并同意
        <a href="/agreement/register.html">《种草达人进阶购买协议》</a>
      </div>
    </div>
    <!-- 订单金额 -->
    <van-popup v-model:show="isShowPay" position="bottom" safe-area-inset-bottom round :overlay="false">
      <div class="flex flex-right align-center ordertrue-payinfo">
        <div class="ordertrue-payinfo-l">
          <p class="ordertrue-payinfo-l-p1">
            共 {{buyNum}} 件，<span>实付</span><span>¥{{parseFloat(realPayPrice/100).toFixed(2)}}</span>
          </p>
          <p class="ordertrue-payinfo-l-p2" v-if="goodsDetail.channel != 3 && rewardScore > 0">确认收货后，可获取{{rewardScore}}龙珠</p>
          <!-- 种草商品 -->
          <p class="ordertrue-payinfo-l-p2" v-if="goodsDetail.channel == 3">种草达人进阶商品暂时不奖励龙珠</p>
        </div>
        <div class="ordertrue-payinfo-btn">
          <van-button type="default" color="#E62129" round class="ordertrue-payinfo-btn-anniu" @click="submitOrderFunc">提交订单</van-button>
        </div>
      </div>
    </van-popup>
    <!-- 优惠券列表 -->
    <van-popup
      v-model:show="showList"
      round
      position="bottom"
      style="height: 90%; padding-top: 4px;"
    >
      <van-coupon-list
        :show-exchange-bar="false"
        :coupons="coupons"
        :chosen-coupon="chosenCoupon"
        @change="onChange"
      />
    </van-popup>
    <!-- 选择支付方式 -->
    <van-popup  v-model:show="sPayType" position="bottom" round>
      <div class="ordertrue-paytype">
        <div class="ordertrue-paytype-title">选择支付方式</div>
        <van-radio-group v-model="checkedPayType" @change="selectPayTypeFunc">
          <div class="flex flex-between align-center ordertrue-paytype-li">
            <div class="ordertrue-paytype-li-wx"><img src="../../../assets/img/order/wx.png" alt="">微信支付</div>
            <van-radio name="WXPAY" checked-color="#E62129"></van-radio>
          </div>
          <!-- <div class="flex flex-between align-center ordertrue-paytype-li">
            <div class="ordertrue-paytype-li-alipay"><img src="../../../assets/img/order/alipay.png" alt="">支付宝支付</div>
            <van-radio name="ALIPAY" checked-color="#E62129"></van-radio>
          </div> -->
        </van-radio-group>
      </div>
    </van-popup>
    <!-- sku选择 -->
    <goods-sku ref="goodssku" @buynumfunc="buyGoodsFunc"></goods-sku>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
  @import "./index.scss";
</style>