import {
    getUserInfo,
    getDetaultAddress,
    getFreight,
    getYhInfo,
    createOrder,
    wxPay,
    aliPay
} from '@/utils/order.js'
import {
    getAddressList
} from '@/utils/address.js';
import goodsSku from '@/components/goodsSku';
import { provide, ref } from 'vue';
export default {
    components: {
        goodsSku
    },
    setup() {
        let isSku = ref(false); // 商品规格选择弹窗
        provide('issku', isSku);
        let platfrom = ref('ordertrue');
        provide('platfrom', platfrom)
        return {
            isSku
        }
    },
    data() {
        return {
            isShowPage: false,
            userInfo: '',
            checkedIntegral: '',
            isShowPay: true,
            goodsDetail: '',
            buyNum: 0,
            goodsUrl: '',
            price: 0,
            originPrice: 0,
            spec1Name: '',
            spec2Name: '',
            isSpec: false,
            addressInfo: '',
            goodsId: '',
            freightNum: 0,
            addressList: [],
            payPrice: 0,
            showList: false,
            chosenCoupon: -1,
            coupons: [{
                available: 1,
                condition: '无使用门槛\n最多优惠12元',
                reason: '',
                value: 150,
                name: '优惠券名称',
                startAt: 1489104000,
                endAt: 1514592000,
                valueDesc: '1.5',
                unitDesc: '元',
            }],
            couponPrice: 0,
            sPayType: false,
            checkedPayType: 'WXPAY',
            useBount: 0,
            useBountAmount: 0, // 积分抵扣金额
            payMethod: '', // 积分兑换方式 1全额支付 2可以按比例积分抵扣 3固定抵扣金额
            isDaPerson: false, // true 达人  false 普通会员
            intergral: 0, // 积分
            couponSns: '', // 优惠券编号
            specId: '', // 规格ID
            rewardScore: 0, // 奖励积分
            checkedhttp: false,
            shareMemberId: '',
            activityId: '',
            integralInfo: ''
        }
    },
    computed: {
        realPayPrice() {
            let aPrice = 0;
            aPrice = this.buyNum * this.price + this.freightNum - (this.checkedIntegral ? this.useBountAmount : 0) - this.couponPrice;
            return aPrice;
        }
    },
    created() {
        this.isDaPerson = this.$cookies.get('isDaPerson');
        this.goodsDetail = JSON.parse(sessionStorage.getItem('goodsdetail'));
        this.goodsId = this.$route.query.goodsId;
        this.buyNum = this.$route.query.buyNum;
        sessionStorage.setItem('buyNum', this.buyNum);
        this.goodsUrl = this.$route.query.goodsUrl;
        this.price = this.$route.query.price;
        this.originPrice = this.$route.query.originPrice;
        this.spec1Name = this.$route.query.spec1Name;
        this.spec2Name = this.$route.query.spec2Name;
        this.specId = this.$route.query.specId;
        this.shareMemberId = this.$route.query.shareMemberId;
        this.activityId = this.$route.query.activityId;
        this.rewardScore = this.buyNum * (this.goodsDetail.score ? this.goodsDetail.score : 0);
        if (this.spec1Name || this.spec2Name) {
            this.isSpec = true;
        }

        this.getUserInfoFunc();
        // 判断用户是否已经选择了地址
        if (sessionStorage.getItem('addressInfo')) {
            this.addressInfo = JSON.parse(sessionStorage.getItem('addressInfo'));
            this.getFreightFunc();
        } else {
            this.getDetaultAddressFunc();
        }
        this.getAddressListFunc();
    },
    methods: {
        // 获取用户信息
        getUserInfoFunc() {
            getUserInfo({ memberId: this.$cookies.get('memberId') }).then(res => {
                console.log('userInfo', res)
                if (res && res.code == 0) {
                    this.userInfo = res.data;
                    setTimeout(() => {
                        this.isShowPage = true;
                    }, 500)
                    this.getYhInfoFunc();
                }
            })
        },
        // 获取默认地址
        getDetaultAddressFunc() {
            let data = {
                memberId: this.$cookies.get('memberId')
            }
            getDetaultAddress(data).then(res => {
                console.log('地址', res)
                if (res && res.code == 0) {
                    this.addressInfo = res.data || {};
                    console.log('判断地址', this.addressInfo && JSON.stringify(this.addressInfo) != '{}')
                    if (this.addressInfo.addressId) {
                        this.getFreightFunc();
                    }
                }
            })
        },
        // 获取地址列表
        getAddressListFunc() {
            let data = {
                memberId: this.$cookies.get('memberId')
            }
            getAddressList(data).then(res => {
                console.log('地址', res.data)
                if (res && res.code == 0) {
                    this.addressList = res.data.addressList ? res.data.addressList : [];
                }
            })
        },
        // 获取运费
        getFreightFunc() {
            let data = {
                memberId: this.$cookies.get('memberId'),
                addressId: this.addressInfo.addressId,
                goodsId: this.goodsId,
                goodsNum: this.buyNum,
                specId: this.specId
            }
            getFreight(data).then(res => {
                console.log('运费', res)
                if (res && res.code == 1) {
                    this.freightNum = res.result.freightAmount > 0 ? res.result.freightAmount : 0;
                }
            })
        },
        //获取可用积分和优惠券
        getYhInfoFunc() {
            let data = {
                memberId: this.$cookies.get('memberId'),
                goodsId: this.goodsId,
                tradeAmt: this.buyNum * this.price,
                channel: ''
            }
            getYhInfo(data).then(res => {
                console.log('优惠', res)
                if (res && res.code == 1) {
                    // 处理优惠券
                    let couponArr = res.result.can_use_couponList;
                    let newCouponArr = [];
                    couponArr.forEach(item => {
                        let aStartTime = new Date(item.validStartTime.substring(0, 10));
                        let aEndTime = new Date(item.validEndTime.substring(0, 10));
                        let aJson = {
                            available: 1,
                            id: item.id,
                            condition: '满' + parseFloat(item.limitAmount / 100).toFixed() + '元使用',
                            reason: '',
                            value: item.money,
                            name: item.name,
                            startAt: aStartTime.getTime() / 1000,
                            endAt: aEndTime.getTime() / 1000,
                            valueDesc: parseFloat(item.money / 100).toFixed(),
                            unitDesc: '元',
                            sn: item.sn
                        }
                        newCouponArr.push(aJson)
                    })
                    this.coupons = newCouponArr;
                    this.payMethod = res.result.good_detail.pay_method;

                    // 积分折算成金额
                    this.integralInfo = res.result;

                    // 判断用户积分是否大于0
                    if (this.userInfo.bonus_amount > 0) {
                        if (this.payMethod == 1) { //全额支付
                            this.useBount = 0;
                            this.useBountAmount = 0;
                        } else if (this.payMethod == 2) {
                            // 如果pay_method=2,则购买价格*max_points/100 * bonus_verify_rate/100，最大可抵扣积分
                            // this.useBount = this.userInfo.bonus_amount >= res.result.user_bonus_amount ? res.result.user_bonus_amount : this.userInfo.bonus_amount;

                            this.useBount = parseInt(this.userInfo.bonus_amount >= res.result.good_detail.max_points ? this.buyNum * this.price / 100 * res.result.good_detail.max_points / 100 * res.result.bonus_verify_rate / 100 : this.userInfo.bonus_amount);

                            // 按比例积分抵扣
                            this.useBountAmount = parseFloat(this.useBount / res.result.bonus_verify_rate * 100);
                        } else if (this.payMethod == 3) {
                            // 如果pay_method=3,则max_points * bonus_verify_rate/100，最大可抵扣积分
                            // 固定金额
                            // this.useBount = this.userInfo.bonus_amount >= res.result.good_detail.max_points ? res.result.good_detail.max_points : this.userInfo.bonus_amount;

                            this.useBount = parseInt(this.userInfo.bonus_amount >= res.result.good_detail.max_points ? res.result.good_detail.max_points : this.userInfo.bonus_amount);


                            this.useBountAmount = parseFloat(this.useBount / res.result.bonus_verify_rate * 100);
                        }

                        // 判断可使用积分金额是否大于商品总金额
                        this.useBountAmount = (this.buyNum * this.price - this.freightNum) >= this.useBountAmount ? this.useBountAmount : this.buyNum * this.price;
                        this.useBount = parseInt(this.useBountAmount  / 100 * res.result.bonus_verify_rate);
                        console.log(this.useBountAmount, this.useBount)
                    } else {
                        this.useBount = 0;
                        this.useBountAmount = 0;
                    }
                }
            })
        },
        // 跳转到地址选择
        gotoAddressFunc() {
            let url = '/addressList';
            this.$router.push({
                path: url,
                query: {
                    platfrom: 'order'
                }
            })
        },
        // 打开优惠券选择
        openCoupon() {
            if (this.coupons.length == 0) return false;
            this.showList = true;
        },
        // 选择优惠券
        onChange(index) {
            this.showList = false;
            this.chosenCoupon = index;
            if (index < 0) {
                this.couponPrice = 0;
                this.couponSns = '';
            } else {
                this.couponPrice = this.coupons[index].value;
                this.couponSns = this.coupons[index].sn;
            }

            // 积分折算成金额
            // 判断用户积分是否大于0
            if (this.userInfo.bonus_amount > 0) {
                if (this.payMethod == 1) { //全额支付
                    this.useBount = 0;
                    this.useBountAmount = 0;
                } else if (this.payMethod == 2) {
                    // 如果pay_method=2,则购买价格*max_points/100 * bonus_verify_rate/100，最大可抵扣积分
                    // this.useBount = this.userInfo.bonus_amount >= this.integralInfo.user_bonus_amount ? this.integralInfo.user_bonus_amount : this.userInfo.bonus_amount;

                    this.useBount = parseInt(this.userInfo.bonus_amount >= this.integralInfo.good_detail.max_points ? this.buyNum * this.price / 100 * this.integralInfo.good_detail.max_points / 100 * this.integralInfo.bonus_verify_rate / 100 : this.userInfo.bonus_amount);

                    // 按比例积分抵扣
                    this.useBountAmount = parseFloat(this.useBount / this.integralInfo.bonus_verify_rate * 100);
                } else if (this.payMethod == 3) {
                    // 如果pay_method=3,则max_points * bonus_verify_rate/100，最大可抵扣积分
                    // 固定金额
                    // this.useBount = this.userInfo.bonus_amount >= this.integralInfo.good_detail.max_points ? this.integralInfo.good_detail.max_points : this.userInfo.bonus_amount;

                    this.useBount = parseInt(this.userInfo.bonus_amount >= this.integralInfo.good_detail.max_points ? this.integralInfo.good_detail.max_points : this.userInfo.bonus_amount);


                    this.useBountAmount = parseFloat(this.useBount / this.integralInfo.bonus_verify_rate * 100);
                }

                // 判断可使用积分金额是否大于商品总金额
                this.useBountAmount = (this.buyNum * this.price - this.freightNum - this.couponPrice) >= this.useBountAmount ? this.useBountAmount : this.buyNum * this.price;
                this.useBount = parseInt(this.useBountAmount  / 100 * this.integralInfo.bonus_verify_rate);
                console.log(this.useBountAmount, this.useBount)
            } else {
                this.useBount = 0;
                this.useBountAmount = 0;
            }
        },
        // 选择支付方式
        selectPayTypeFunc(val) {
            console.log('payType', val)
            this.sPayType = false;
        },
        openGuigeFunc() {
            this.isSku = true;
            this.$refs.goodssku.specArrFunc();
        },
        // 购买商品
        buyGoodsFunc(val, specname) {
            this.buyNum = val;
            let arr = specname.split(';');
            this.spec1Name = arr[0] ? arr[0] : '';
            this.spec2Name = arr[1] ? arr[1] : '';
        },
        // 提交订单
        submitOrderFunc() {
            // if (this.$cookies.get('userType') == '9') {
            //     this.$toast('您的用户类型暂不支持购买商品！')
            //     return false;
            // }
            
            if (!this.addressInfo || JSON.stringify(this.addressInfo) === '{}') {
                this.$toast('请添加收货地址')
                return false;
            }

            if (this.goodsDetail.channel == 3 && !this.checkedhttp) {
                this.$toast('请查看《种草达人进阶购买协议》阅读同意协议后，才可正常购买')
                return false;
            }

            let data = {
                memberId: this.$cookies.get('memberId'),
                goodsId: this.goodsId,
                goodsNum: this.buyNum,
                addressId: this.addressInfo.addressId,
                intergral: this.intergral,
                couponSns: this.couponSns,
                specId: this.specId,
                channel: this.goodsDetail.channel,
                shareMemberId: this.shareMemberId
            };
            if (this.activityId) {
                data = {
                    memberId: this.$cookies.get('memberId'),
                    goodsId: this.goodsId,
                    goodsNum: this.buyNum,
                    addressId: this.addressInfo.addressId,
                    intergral: this.intergral,
                    couponSns: this.couponSns,
                    specId: this.specId,
                    channel: this.goodsDetail.channel,
                    shareMemberId: this.shareMemberId,
                    activityId: this.activityId ? this.activityId : ''
                }
            }
            createOrder(data).then(res => {
                console.log('order', res)
                if (res && res.code == 1) {
                    this.$toast.loading({
                        message: '正在下单中',
                        mask: true,
                        forbidClick: true,
                    });

                    if (this.checkedPayType == 'WXPAY') {
                        // 微信支付
                        if (typeof WeixinJSBridge === 'undefined') { // 微信浏览器内置对象。参考微信官方文档
                            if (document.addEventListener) {
                                document.addEventListener('WeixinJSBridgeReady', this.wxPayFunc(res.result.orderNum, res.result.channel), false)
                            } else if (document.attachEvent) {
                                document.attachEvent('WeixinJSBridgeReady', this.wxPayFunc(res.result.orderNum, res.result.channel))
                                document.attachEvent('onWeixinJSBridgeReady', this.wxPayFunc(res.result.orderNum, res.result.channel))
                            }
                        } else {
                            console.log('准备调用微信支付')
                            this.wxPayFunc(res.result.orderNum, res.result.channel);
                        }
                    } else { // 支付宝支付
                        if (typeof AlipayJSBridge === 'undefined') {
                            if (document.addEventListener) {
                                document.addEventListener('AlipayJSBridgeReady', this.aliPayFunc(res.result.orderNum, res.result.channel), false)
                            } else if (document.attachEvent) {
                                document.attachEvent('AlipayJSBridgeReady', this.aliPayFunc(res.result.orderNum, res.result.channel))
                                document.attachEvent('onAlipayJSBridgeReady', this.aliPayFunc(res.result.orderNum, res.result.channel))
                            }
                        } else {
                            this.aliPayFunc(res.result.orderNum, res.result.channel)
                        }
                    }
                }
            })
        },
        // 调起支付
        wxPayFunc(orderNo, channel) {
            let data = {
                memberId: this.$cookies.get('memberId'),
                orderNo: orderNo,
                channel: channel
            }
            let _this = this;
            wxPay(data).then(res => {
                console.log('微信支付', res)
                    //调用微信支付
                WeixinJSBridge.invoke(
                    'getBrandWCPayRequest', {
                        "appId": res.result.sdk_appid, //公众号名称，由商户传入
                        "timeStamp": res.result.sdk_timestamp, //时间戳，自1970年以来的秒数
                        "nonceStr": res.result.sdk_noncestr, //随机串
                        "package": res.result.sdk_package,
                        "signType": res.result.sdk_signtype, //微信签名方式：
                        "paySign": res.result.sdk_paysign //微信签名
                    },
                    function(res) {
                        if (res.err_msg == "get_brand_wcpay_request:ok") {
                            _this.$toast.success({
                                message: '支付成功！',
                                forbidClick: true,
                            });
                            setTimeout(() => {
                                _this.$toast.clear();
                                _this.$router.push({
                                    path: '/paySuccess'
                                })
                            }, 500)
                        }
                        if (res.err_msg == "get_brand_wcpay_request:cancel") {
                            _this.$toast.fail({
                                message: '交易取消！',
                                forbidClick: true,
                            })
                            setTimeout(() => {
                                window.location.reload();
                            }, 1500);
                        }
                        if (res.err_msg == "get_brand_wcpay_request:fail") {
                            _this.$toast('支付失败！');
                        }

                        setTimeout(() => {
                            _this.$toast.clear();
                        }, 1500);
                    }
                );
            })
        },
        // 支付宝支付
        aliPayFunc(orderNo, channel) {
            let data = {
                memberId: this.$cookies.get('memberId'),
                orderNo: orderNo,
                channel: channel
            }
            aliPay(data).then(res => {
                // alert(JSON.stringify(res))
                window.location.href = res.result;
            })
        }
    },
}